import React from "react";
import django from "../assets/Icons/django.png";
import flutter from "../assets/Icons/flutter.png";
import rct from "../assets/Icons/react.png";
import opencv from "../assets/Icons/opencv.png";
import flask from "../assets/Icons/flask.png";
import github from "../assets/Icons/github.png";
import firebase from "../assets/Icons/firebase.png";
import mongodb from "../assets/Icons/mongodb.png";

function Frameworks() {
  const stacks = [
    {
      id: 1,
      src: rct,
      title: "React",
    },
    {
      id: 2,
      src: flutter,
      title: "Flutter",
    },
    {
      id: 3,
      src: django,
      title: "Django",
    },
    {
      id: 4,
      src: opencv,
      title: "OpenCV",
    },
    {
      id: 5,
      src: flask,
      title: "Flask",
    },
    {
      id: 6,
      src: github,
      title: "Github",
    },
    {
      id: 7,
      src: firebase,
      title: "Firebase",
    },
    {
      id: 8,
      src: mongodb,
      title: "MongoDB",
    },
  ];
  return (
    <div
      name="Frameworks"
      className="grid-rows-4 grid-flow-col gap-4 w-full bg-gradient-to-b pt-16 from-blue-300 to-blue-200"
    >
      <div className=" max-w-screen-lg px-4 mx-auto flex flex-col">
        <div className="pb-8 sm:pl-0 pl-12">
          <p className="text-4xl font-bold inline border-b-4 border-gray-400">
            Framework & Database
          </p>
        </div>

        <div className="grid sm:grid-cols-5 grid-cols-3 md:grid-cols-6 gap-12 px-12 sm:px-0 ">
          {stacks.map((stack) => (
            <div key={stack.id} className="h-1/4 pt-4 w-fit">
              <img
                className="rounded-md object-cover duration-200 hover:scale-105 z-50"
                src={stack.src}
                alt=""
              />
              <div className="flex items-center justify-center">
                <p className="text-xl font-bold text-gray-800">{stack.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Frameworks;
