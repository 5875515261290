import React from "react";
import { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-scroll";

function Navbar() {
  const [navi, setNavi] = useState(false);

  const navheaders = [
    {
      id: 1,
      name: "Home",
    },
    {
      id: 2,
      name: "Portfolio",
    },
    {
      id: 3,
      name: "Skill",
    },
    {
      id: 4,
      name: "Contact",
    },
  ];
  return (
    <div className="flex justify-between items-center w-full h-16 text-white bg-sky-600 fixed ">
      <div className="font-gaurablogo text-3xl font-bold px-8 sm:pl-48 pl-16">
        Gaurab Shrestha
      </div>
      <ul className="hidden sm:flex cursor-pointer ">
        {navheaders.map((item) => (
          <li
            key={item.id}
            className="px-12 text-xl capitalize font-bold text-white-600 hover:scale-100 duration-100"
          >
            <Link to={item.name} smooth duration={500}>
              {item.name}
            </Link>
          </li>
        ))}
      </ul>

      <div
        onClick={() => setNavi(!navi)}
        className="pr-4 cursor-pointer z-10 text-white-500 sm:hidden"
      >
        {navi ? <FaTimes size={26} /> : <FaBars size={26} />}
      </div>

      {navi && (
        <ul className="flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-blue-400 to-blue-500">
          {navheaders.map((item) => (
            <li
              key={item.id}
              className="py-8 capitalize text-2xl text-white cursor-pointer hover:scale-100 duration-100 "
            >
              <Link to={item.name} smooth duration={500}>
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Navbar;
