import React from "react";
import { useState } from "react";
import validator from "validator";

const Contact = () => {
  const [emailError, setEmailError] = useState("");
  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("🙂🙂");
    } else {
      setEmailError("Enter valid email!");
    }
  };
  return (
    <div
      name="Contact"
      className="pt-24 w-full sm:pl-0 pl-12 bg-gradient-to-b from-blue-200 to-blue-300 "
    >
      <div className=" max-w-screen-lg px-4 mx-auto flex flex-col">
        <div className="pb-4">
          <p className="text-4xl font-bold inline border-b-4 border-gray-400">
            Contact
          </p>
          <p className="pt-6">You can reach out to me from here directly</p>
        </div>

        <form
          className="flex flex-col sm:items-center items-start justify-center w-full "
          action="https://formspree.io/f/mjvzyppd"
          method="POST"
        >
          <div className="py-4 shadow-md  flex flex-col w-full sm:items-center items-startjustify-center">
            <input
              className="my-2 p-2 sm:w-2/3 w-3/4 "
              type="text"
              placeholder="Your name"
            />
            <input
              className="my-2 p-2 shadow-slate-400 sm:w-2/3 w-3/4 "
              type="email"
              name="email"
              id=""
              placeholder="Your email"
              onChange={(e) => validateEmail(e)}
            />
          </div>

          <textarea
            className="sm:w-2/3 w-3/4 p-2"
            name="message"
            id=""
            cols="30"
            rows="10"
          ></textarea>
          <span
            style={{
              fontWeight: "bold",
              color: "red",
            }}
          >
            {emailError}
          </span>
          <button className="sm:w-28 w-20 mb-8 text-center items-center h-12 mt-4 rounded-xl border-x-slate-400 text-xl transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105  duration-300  bg-gradient-to-b from-cyan-700 to-cyan-500 text-fuchsia-50">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
