import React from "react";

const Footer = () => {
  return (
    <div>
      <footer class="p-4 bg-gray-700 shadow-2xl sm:flex sm:items-center sm:justify-between text-center md:p-6 dark:bg-gray-800 h-24">
        <span class="text-md text-white sm:text-center dark:text-white">
          © 2022{" "}
          <a href="/#" class="hover:underline">
            Gaurab Shrestha
          </a>
          . All Rights Reserved.
        </span>
        <ul class="flex sm:flex-wrap sm:items-center justify-center text-center  mt-3 text-md text-white dark:text-white sm:mt-0">
          <li>
            <a href="/#" class="mr-4 hover:underline md:mr-6 ">
              About
            </a>
          </li>
          <li>
            <a href="/#" class="mr-4 hover:underline md:mr-6">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="/#" class="mr-4 hover:underline md:mr-6">
              Licensing
            </a>
          </li>
          <li>
            <a href="/#" class="hover:underline">
              Contact
            </a>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default Footer;
