import React from "react";

const Experiences = () => {
  const stacks = [
    {
      id: 1,
      title: "Javascript",
      color: "bg-gradient-to-b from-purple-400 to-purple-700",
      style: "w-[80%]",
      proficency: 80,
    },
    {
      id: 2,
      title: "C/C++",
      color: "bg-gradient-to-b from-blue-700 to-blue-500",
      style: "w-[70%]",
      proficency: 70,
    },
    {
      id: 3,
      title: "Python",
      color: "bg-gradient-to-b from-emerald-500 to-emerald-700",
      style: "w-[70%]",
      proficency: 70,
    },
    {
      id: 4,
      title: "HTML/CSS",
      color: "bg-gradient-to-br from-orange-300 to-orange-600",
      style: "w-[90%]",
      proficency: 90,
    },

  ];
  return (
    <div
      name="Skill"
      className="pt-16 w-full bg-gradient-to-b from-blue-200 to-blue-300"
    >
      <div className=" max-w-screen-lg p-4 mx-auto flex flex-col">
        <div className="pb-8 sm:pl-0 pl-12">
          <p className="text-4xl font-bold inline border-b-4 border-gray-400">
            Languages
          </p>
        </div>
      </div>
      {stacks.map((stack) => (
        <div
          className="max-w-screen-lg sm:pl-4 pl-16 sm:pr-0 pr-12 pb-8 mx-auto flex flex-col"
          key={stack.id}
        >
          <div className="flex justify-between mb-1 ">
            <span className="text-base font-medium text-green-900 dark:text-white">
              {stack.title}
            </span>
            <span className="text-sm font-medium text-green-900 dark:text-white">
              {stack.proficency}%
            </span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-300">
            <div
              className={
                "  transition h-2.5 rounded-full" +
                " " +
                stack.style +
                " " +
                stack.color
              }
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Experiences;
