import React from "react";
import weather from "../assets/weather.jpg";
import portfolio from "../assets/portfolio.jpg";
import house from "../assets/house.jpg";
import surveillance from "../assets/surveillance.jpg";
import attendance from "../assets/house.jpg";

function Portfolio() {
  const stacks = [
    {
      id: 0,
      src: portfolio,
      title: "YTS Clone",
      href: "https://github.com/Gaurab17/YTSClone.git",
    },
    {
      id: 1,
      src: house,
      title: "Ghar-Vada",
      href: "https://github.com/Gaurab17/House-Rent-Manager",
    },
    {
      id: 2,
      src: weather,
      title: "Pocket-Weather",
      href: "https://github.com/Gaurab17/Pocket-Weather-",
    },
    {
      id: 3,
      src: surveillance,
      title: "Home Surveillance",
      href: "https://github.com/KabichNeu/HomeSurveillance",
    },
    {
      id: 4,
      src: portfolio,
      title: "Portfolio",
      href: "https://github.com/Gaurab17/Portfolio",

    },
    {
      id: 5,
      src: attendance,
      title: "Attendance System",
      href: "https://github.com/Gaurab17/HCI-MINI-PROJ",

    }
  ];
  return (
    <div
      name="Portfolio"
      className="grid-rows-4 grid-flow-col gap-4 w-full bg-gradient-to-b from-blue-300 to-blue-200 "
    >
      <div className=" max-w-screen-lg px-4 mx-auto flex flex-col">
        <div className="pb-8 sm:pt-0 md:pt-0 pt-8 sm:pr-0 sm:pl-0 pl-12">
          <p className="text-4xl  font-bold inline border-b-4 border-gray-400">
            PROJECTS
          </p>
        </div>

        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-12 px-12 sm:px-0 ">
          {stacks.map((stack) => (
            <div
              key={stack.id}
              className="shadow-md shadow-gray-400 rounded-lg h-1/4 w-fit"
            >
              <img
                className="rounded-md object-cover duration-200 hover:scale-105"
                src={stack.src}
                alt=""
              />
              <div className="flex items-center justify-center">
                <p className="text-xl font-bold text-gray-800">{stack.title}</p>
                <button className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105">
                  <a href={stack.href} target="_blank" rel="noreferrer">
                    Code
                  </a>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
