import React from "react";
import me from "../assets/me1.png";
import { FaDownload } from "react-icons/fa";
import Typewriter from 'typewriter-effect';

function Home() {
  return (
    <section className="bg-gradient-to-b from-blue-200 to-blue-300 sm:0 pt-16">
      <div
        name="Home"
        className="flex sm:flex-row sm:pt-20 pt-0 flex-col sm:pl-24 pl-0 justify-between items-center w-full container-custom sm:pb-40 pb-8"
      >
        <div className="flex items-center ml-12 mb-12 sm:px-0 px-8  justify-center ">
          <img className="opacity-35 justify-start" src={me} alt="" />
        </div>

        <div className="text-3xl text-gray-800 px-8 sm:pl-32 pl-12 sm:text-start text-center ">
          <Typewriter
            className="text-4xl text-gray-800 sm:text-start text-center"
            onInit={(typewriter) => {
              typewriter
                .typeString("Hi, There!!")
                .pauseFor(1000)
                .deleteAll()
                .typeString("It's me, Gaurab Shrestha.")
                .start();
            }}
          />

          <h1 className="text-4xl pt-[20px]">
            I am a web developer and an android app developer. <br />
          </h1>
          <br />
          Feel free to know more about me.
          <div className="flex sm:justify-start justify-center mt-12 ">
            <a
              className="w-52 flex flex-row items-center h-12 pl-2 rounded-xl border-x-slate-400 text-xl transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300  bg-gradient-to-b from-cyan-700 to-cyan-500 text-fuchsia-50"
              href="/resume.pdf"
              download
            >
              <FaDownload size={16} className="mr-4" />
              Download My CV
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
