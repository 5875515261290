import { FaGithub, FaLinkedin, FaFacebook } from "react-icons/fa";
import { BsFillPersonFill } from "react-icons/bs";
import React from "react";

function Sociallinks() {
  return (
    <div className="bottom-[0%] left-1/2 -translate-x-1/2 rounded-3xl w-60 h-12 bg-slate-300 mb-8 opacity-50 items-center hover:pointer fixed">
      <ul className="flex flex-row justify-between pt-2 px-2">
        <li className="pr-4">
          <a href="https://www.github.com/gaurab17" target="__blank">
            <FaGithub size={30} />
          </a>
        </li>

        <li className="pr-4">
          <a
            href="https://www.linkedin.com/in/gaurab-stha-099249216/"
            target="__blank"
          >
            <FaLinkedin size={30} />
          </a>
        </li>

        <li className="pr-4">
          <a href="https://www.facebook.com/GaurabStha17/" target="__blank">
            <FaFacebook size={30} />
          </a>
        </li>

        <li>
          <a href="mailto:gaurabstha001@gmail.com" target="__blank">
            <BsFillPersonFill size={30} />
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Sociallinks;
