import "./App.css";
import Contact from "./components/Contact";
import Experiences from "./components/Experiences";
import Footer from "./components/Footer";
import Frameworks from "./components/Frameworks";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Portfolio from "./components/Portfolio";
import Sociallinks from "./components/Sociallinks";

function App() {
  return (
    <div>
      <Navbar />
      <Home />
      <Sociallinks />
      <Portfolio />
      <Experiences />
      <Frameworks />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
